import React from 'react'
import { connect } from 'react-redux'

import Grid from '@material-ui/core/Grid'
import Link from '@material-ui/core/Link'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import Chip from '@material-ui/core/Chip'

import { colors } from 'consts'
import { unixToDate } from 'helper'
import { actions } from 'teams/redux'
import { confirmDelete } from '../../actions'
import { leagueSlice } from 'league/redux'

class TeamSummary extends React.Component {
  render() {
    return (
      <Paper elevation={0} style={{ padding: '4%' }}>
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="center"
          spacing={3}
        >
          <Grid item xs={12}>
            <Typography variant="h5">Summary</Typography>
          </Grid>
          <Grid item xs={12}>
            <hr />
          </Grid>
          <Grid item xs={6}>
            <Typography variant="subtitle1">Players:</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="subtitle1">
              {this.props.team.roster.length}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="subtitle1">League:</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="subtitle1">
              {this.props.league?.ID ? (
                <Link href={`/league/${this.props.league.ID}`} target="_blank">
                  <Button color="primary">{this.props.league.name}</Button>
                </Link>
              ) : (
                <i>None</i>
              )}
            </Typography>
          </Grid>
          {this.props.league?.ID ? (
            <>
              <Grid item xs={6}>
                <Typography variant="subtitle1">Start:</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="subtitle1">
                  {unixToDate(this.props.league.start_date).format('M/D/YYYY')}
                </Typography>
              </Grid>
            </>
          ) : null}
          {this.props.league?.ID && this.props.league.invoice ? (
            <>
              <Grid item xs={6}>
                <Typography variant="subtitle1">Balance:</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="subtitle1">
                  <Chip
                    size="small"
                    label={<b>${this.props.league.invoice.balance}</b>}
                    style={{
                      color:
                        this.props.league.invoice.balance <= 0
                          ? colors.green_strong
                          : colors.red_strong,
                      backgroundColor:
                        this.props.league.invoice.balance <= 0
                          ? colors.green_mild
                          : colors.red_mild,
                      margin: 5,
                    }}
                  />
                </Typography>
              </Grid>
            </>
          ) : null}
          {this.props.team.suspended_players && this.props.team.suspended_players.length > 0 &&
            <>
              <Grid item xs={6}>
                <Typography variant="subtitle1">Suspended Players:</Typography>
              </Grid>
              <Grid item xs={6}>
                {this.props.team.suspended_players.map((player, index) => (
                  <Typography key={index} variant="body2">
                    {player}
                  </Typography>
                ))}
              </Grid>
          </>
          }
          {this.props.league?.ID && this.props.league.invoice ? (
            <Grid item xs={12}>
              <Link
                href={`/invoice/${this.props.league.invoice.ID}`}
                target="_blank"
              >
                <Button
                  style={{ margin: 10, textAlign: 'right' }}
                  className="save-btn"
                >
                  View invoice
                </Button>
              </Link>
            </Grid>
          ) : null}
          {this.props.league?.ID ? (
            <Grid item xs={12}>
              <Button
                className="save-btn"
                style={{
                  margin: 10,
                  textAlign: 'right',
                  backgroundColor: colors.yellow_mild,
                  color: colors.yellow_strong,
                }}
                onClick={() => this.props.openEditLeagueModal()}
              >
                {/*TODO: select fee if adding to team*/}
                {this.props.league ? 'Change' : 'Add to'} league
              </Button>
            </Grid>
          ) : null}
          <Grid item xs={12}>
            <Button className="save-btn" onClick={this.props.openBroadcast}>
              Broadcast message
            </Button>
          </Grid>
          {!this.props.league?.ID && !this.props.leagues.length ? (
            <Grid item xs={12}>
              <Button
                className="save-btn"
                style={{
                  margin: 10,
                  textAlign: 'right',
                  backgroundColor: colors.red_mild,
                  color: colors.red_strong,
                }}
                onClick={() => this.props.confirmDelete(this.props.team.ID)}
              >
                Delete Team
              </Button>
            </Grid>
          ) : null}
        </Grid>
      </Paper>
    )
  }
}

const mapStateToProps = (state, ownProps) => ({
  team: state.teams.team,
  league: state.teams.team.current_league,
  redirect: state.teams.redirect,
  leagues: state.teams.team?.leagues?.filter(l => l.invoice),
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  openEditLeagueModal: () =>
    dispatch(actions.toggleChangeLeagueModal({ open: true })),
  confirmDelete: id => dispatch(confirmDelete(id)),
  openBroadcast: () =>
    dispatch(
      leagueSlice.actions.updateBroadcastMessage({ key: 'open', value: true })
    ),
})

export default connect(mapStateToProps, mapDispatchToProps)(TeamSummary)
