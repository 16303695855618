import React from 'react'
import { connect } from 'react-redux'

import { withStyles } from '@material-ui/core/styles'
import Link from '@material-ui/core/Link'
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import { unixToDate, unixToTime } from './../../../helper'

const styles = theme => ({
  maxheight: {
    maxHeight: 650,
    overflow: 'scroll',
  },
  root: {
    padding: 2,
  },
})

class Schedule extends React.Component {
  render() {
    return (
      <Paper elevation={0}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell style={{ textAlign: 'center', width: '10%' }} className="table-header">
                Week
              </TableCell>
              <TableCell style={{ textAlign: 'center', width: '20%' }} className="table-header">
                Date
              </TableCell>
              <TableCell style={{ textAlign: 'center', width: '20%' }} className="table-header">
                Time
              </TableCell>
              <TableCell style={{ textAlign: 'center', width: '25%' }} className="table-header">
                Home
              </TableCell>
              <TableCell style={{ textAlign: 'center', width: '25%' }} className="table-header">
                Away
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {this.props.matches.map(match => (
              <TableRow 
                hover 
                style={{ cursor: 'pointer' }}
                onClick={() => match.event.ID ? window.open(`/calendar/event/${match.event.ID}`, '_blank') : null}>
                <TableCell style={{ textAlign: 'center', width: '10% !important' }}>
                  {match.week}
                </TableCell>
                <TableCell style={{ textAlign: 'center', width: '20% !important' }}>
                  {unixToDate(match.event.start_date, 'X').format('M/D/YYYY')}
                </TableCell>
                <TableCell style={{ textAlign: 'center', width: '20% !important' }}>
                  {unixToTime(match.event.start_date).format('h:mmA')}
                </TableCell>
                <TableCell
                  style={{
                    textAlign: 'center',
                    width: '25% !important',
                    color: match.home_team.ID === this.props.team_id ? 'green' : null,
                  }}
                >
                  {match.home_team.name}
                </TableCell>
                <TableCell
                  style={{
                    textAlign: 'center',
                    width: '25% !important',
                    color: match.away_team.ID === this.props.team_id ? 'green' : null,
                  }}
                >
                  {match.away_team.name}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Paper>
    )
  }
}

const mapStateToProps = (state, ownProps) => ({
  team_id: state.teams.team.ID,
  matches: state.league.league_matches.filter(
    match =>
      match.home_team.ID === state.teams.team.ID ||
      match.away_team.ID === state.teams.team.ID
  ),
})

const mapDispatchToProps = (dispatch, ownProps) => ({})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(Schedule))
