import React from 'react'
import moment from 'moment'

import Link from '@material-ui/core/Link'

const PaymentDefault = ({ payment }) => {
  let parsed = null
  try {
    parsed = JSON.parse(payment.details)
  } catch (e) {
    console.error(e)
  }
  const parsed_date = moment(payment.CreatedAt)
    .subtract(4, 'h')
    .format('M/D/YYYY h:mmA')
  if (!parsed)
    return (
      <small>
        Date: <b style={{ color: 'black' }}>{parsed_date}</b>
        <br />
      </small>
    )
  return (
    <>
      <small>
        Date: <b style={{ color: 'black' }}>{parsed_date}</b>
        <br />
      </small>
      {Object.keys(parsed).map((k, i) => (
        <div key={i}>
          {k === 'details' && (
            <small>
              Payment method: <b style={{ color: 'black' }}>{parsed[k]}</b>
              <br />
            </small>
          )}
          {k === 'number' && (
            <small>
              Check number: <b style={{ color: 'black' }}>{parsed[k]}</b>
              <br />
            </small>
          )}
          {k === 'method' && (
            <small>
              Payment method: <b style={{ color: 'black' }}>{parsed[k]}</b>
              <br />
            </small>
          )}
          {k === 'square_transaction_id' && (
            <small>
              Square transaction:{' '}
              <Link
                target="_blank"
                href={`https://squareup.com/dashboard/sales/transactions/${parsed[k]}`}
              >
                here
              </Link>
              <br />
            </small>
          )}
          {k === 'square_id' && (
            <small>
              Square transaction:{' '}
              <Link
                target="_blank"
                href={`https://squareup.com/dashboard/sales/transactions/${parsed[k]}`}
              >
                here
              </Link>
              <br />
            </small>
          )}
        </div>
      ))}
    </>
  )
}

export default PaymentDefault
