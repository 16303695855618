import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  teams: [],
  team: null,
  query: '',
  team_tab: 'players',
  team_form: {
    facility_id: null,
    name: '',
    color: '#f44336',
    new_player: null,
    new_league: null,
  },
  new_team_dialog_open: false,
  new_player_dialog_open: false,
  league_dialog_open: false,
  change_name_dialog_open: false,
  filter: {
    query: '',
    facility_id: null,
    season_id: null,
    division_id: null,
    age_group: null,
    fetch_leagues: true,
  },
  change_team_dialog_open: false,
  change_team: {
    player: null,
    target_team: null,
  },
  selectedLeagueOrTournament: null,
}

const teamSlice = createSlice({
    name: 'team',
    initialState,
    reducers: {
      getAllTeams: (state, { payload }) => {
        state.teams = payload.teams
      },
      getTeam: (state, { payload }) => {
        state.team = payload.team
      },
      editSearchQuery: (state, { payload }) => {
        state.query = payload.query
      },
      editTeamDetails: (state, { payload }) => {
        state.team_form = {
          ...state.team_form,
          [payload.key]: payload.value,
        }
      },
      changeTab: (state, { payload }) => {
        state.team_tab = payload.tab
      },
      toggleTeamModal: (state, { payload }) => {
        state.new_team_dialog_open = payload.open
      },
      togglePlayerModal: (state, { payload }) => {
        state.new_player_dialog_open = payload.open
      },
      toggleChangeLeagueModal: (state, { payload }) => {
        state.league_dialog_open = payload.open
      },
      toggleChangeTeamNameModal: (state, { payload }) => {
        state.change_name_dialog_open = payload.open
        state.team_form.name = payload.name
      },
      changeFilterDetails: (state, { payload }) => {
        state.filter = {
          ...state.filter,
          [payload.key]: payload.value,
        }
      },
      selectLeagueOrTournament:(state, { payload }) => {
        state.selectedLeagueOrTournament = payload
      },
      toggleChangeTeamModal: (state, { payload }) => {
        state.change_team_dialog_open = payload.open
      },
      updateChangeTeamInfo: (state, { payload }) => {
        state.change_team = {
          ...state.change_team,
          [payload.key]: payload.value,
        }
      },
      getSuspendedPlayers: (state, {payload}) => {
        if (state.suspended_players != payload.suspended) {
          state.team = {
            ...state.team, suspended_players: payload.suspended}
        }
      },
      updateTeamLockRoster: (state, {payload}) => {
        state.team = {
          ...state.team, lock_roster:payload.lock_roster
        }
      },
    },
  }),
  actions = teamSlice.actions

export { teamSlice, actions }
