import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Button } from '@material-ui/core'
import { initTerminalPayment, CheckForPendingAndInit, selectTerminal, getTerminalPaymentConfirmation } from '../actions';
import {actions} from '../redux'

const SquareTerminal = ({ amount, onTerminalConfirmation}) => {

  const dispatch = useDispatch();
  const available_terminals = useSelector((state) => state.invoice.terminals.available_terminals);
  const state = useSelector((state) => state.invoice.terminals.terminal_payment_state)
  const terminal_id = useSelector((state) => state.invoice.terminals.terminal_id)

  const handleSelect = async () => {
    dispatch(selectTerminal());
  };
  
  const pay = async (index) => {
    dispatch(actions.updateTerminalID({terminal_id:available_terminals[index].id}))
    const amount_float = parseFloat(amount);
    dispatch(CheckForPendingAndInit(amount_float, onTerminalConfirmation))
  }

  const confirmPayment = async () => {
    dispatch(getTerminalPaymentConfirmation(onTerminalConfirmation))
  }

  const handleConfirmationClose = async () => {
    dispatch(actions.updateTerminalPaymentState({state:null}))
  }

  const handleConfirmationConfirm = async () => {
    const amount_float = parseFloat(amount);
    dispatch(initTerminalPayment(terminal_id, amount_float, onTerminalConfirmation))
  }
  
  return (
    <>
      <div id="card-container" style={{ minHeight: 89 }}>
        {!state && 
        <Button onClick={handleSelect} className="save-btn" style={{ marginBottom: 20 }}>
          Select Terminal
        </Button>
        }

        {state === 'selecting terminal' && (
          <div>
            {available_terminals.length > 0 ? (
              <ul style={{justifyContent:'center'}}>
                <h3 style={{ marginBottom: '20px' }}>Choose a Terminal</h3>
                <ul style={{ listStyleType: 'none', padding: '0', display: 'flex', flexWrap: 'wrap', justifyContent:'center' }}>
                  {available_terminals.map((terminal, index) => (
                    <li
                      key={index}
                      style={{
                        marginBottom: '15px',
                        listStyleType: 'none',
                      }}
                    >
                      <Button
                        onClick={() => {pay(index)}}
                        className="terminal-btn"
                        style={{
                          padding: '10px 20px',
                          backgroundColor: '#007BFF',
                          color: 'white',
                          borderRadius: '5px',
                          cursor: 'pointer',
                          margin: '0 10px',
                        }}
                      >
                        {terminal.nickname}
                      </Button>
                    </li>
                  ))}
                </ul>
              </ul>
            ) : (
              <>
                <p>No online terminals found</p>
                <Button onClick={handleSelect} className="save-btn" style={{ marginBottom: 20 }}>
                  Try Again
                </Button>
              </>
            )}
          </div>
        )}

        {state === 'processing' && <span>Waiting for Confirmation</span>}
        {state === 'confirmed' && <span>Payment Confirmed</span>}
        {state === 'not found' && 
        <>
          <span>We coudn't find payment confirmation</span>
          <Button onClick={confirmPayment} className="save-btn" style={{ marginBottom: 20 }}>
            Search for payment confirmation again
          </Button>
        </>}
        {state === 'pending_confirmation' && 
          <div>
            <div>
              <h2>Confirm Payment</h2>
              <p>There might be an ongoing payment on the terminal</p>
              <p>Check before confirming</p>
              <button onClick={handleConfirmationClose} className="save-btn" style={{marginRight: '10px'}}>Close</button>
              <button onClick={handleConfirmationConfirm} className="save-btn" style={{marginLeft: '10px'}} >Confirm</button>
            </div>
          </div>
        }
      </div>
    </>
  )
}

export default SquareTerminal;
  