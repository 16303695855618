import React from 'react'
import { connect } from 'react-redux'
import { push } from 'connected-react-router'
import moment from 'moment'

import { withStyles } from '@material-ui/core/styles'
import Link from '@material-ui/core/Link'
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import DeleteIcon from '@material-ui/icons/Delete'
import ProfileIcon from '@material-ui/icons/AssignmentInd'
import StarBorderIcon from '@material-ui/icons/StarBorder'
import Tooltip from '@material-ui/core/Tooltip'
import EjectIcon from '@material-ui/icons/Eject'

import { removePlayer, updateCaptain, getSuspendedPlayers } from 'teams/actions'
import { openDeleteModal } from 'common/actions'
import { colors } from 'consts'
import { actions } from 'teams/redux'
import { getHubspot } from 'helper'

const styles = theme => ({
  maxheight: {
    maxHeight: 650,
    overflow: 'scroll',
  },
  root: {
    padding: 2,
  },
})

class Roster extends React.Component {
  componentDidMount() {
    this.props.getSuspendedPlayers();
  }

  render() {
    const { classes } = this.props
    return (
      <div>
        <Paper elevation={0}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell
                  style={{ textAlign: 'center', width: '20%' }}
                  className="table-header"
                >
                  Full Name
                </TableCell>
                <TableCell
                  style={{ textAlign: 'center', width: '10%' }}
                  className="table-header"
                >
                  Membership expires
                </TableCell>
                <TableCell
                  style={{ textAlign: 'center', width: '10%' }}
                  className="table-header"
                >
                  DoB
                </TableCell>
                <TableCell
                  style={{ textAlign: 'center', width: '10%' }}
                  className="table-header"
                >
                  Age
                </TableCell>
                <TableCell
                  style={{ textAlign: 'center', width: '20%' }}
                  className="table-header"
                >
                  Action
                </TableCell>
              </TableRow>
            </TableHead>
          </Table>
          <div className={classes.maxheight}>
            <Table aria-label="simple table">
              <TableBody>
                {this.props.team.roster.map(player => {
                  let bgColor = null
                  if (player.customer.ID === this.props.team.captain_id) {
                    bgColor = colors.yellow_mild
                  }
                  if (player.customer.is_coach) {
                    bgColor = colors.green_mild
                  }
                  let customerMembership = 'no membership'
                  let valid_until = null
                  player.customer?.member?.memberships?.forEach(membership => {
                    if (!valid_until || membership.valid_until > valid_until) {
                      valid_until = membership.valid_until
                    }
                  })
                  if (valid_until) {
                    customerMembership = moment
                      .unix(valid_until)
                      .format('M/D/YYYY')
                  }
                  let details = null
                  if (player.customer && player.customer.details) {
                    try {
                      details = JSON.parse(player.customer.details)
                    } catch (error) {}
                  }
                  let dob = player.customer.hubspot
                    ? player.customer.hubspot.dob
                    : details && details.dob
                  return (
                    <TableRow
                      key={player.ID}
                      style={{
                        cursor: 'pointer',
                        backgroundColor: bgColor,
                      }}
                    >
                      <TableCell style={{ textAlign: 'center', width: '20%' }}>
                        {player.customer.first_name} {player.customer.last_name}{' '}
                        {player.customer.is_coach && '(coach)'}
                        {player.customer.ID === this.props.team.captain_id &&
                          '(C)'}
                        <img
                          src="https://ps.w.org/leadin/assets/icon-256x256.png?rev=2785153"
                          onClick={() => getHubspot(player.customer.ID)}
                          width="15"
                          style={{ marginLeft: 10 }}
                        />
                        <br />
                        <small>
                          Paid:{' '}
                          {player.payment ? (
                            <span style={{ color: 'green' }}>
                              $
                              {player.payment.reduce((a, b) => a + b.amount, 0)}
                            </span>
                          ) : (
                            <span style={{ color: 'red' }}>$0</span>
                          )}
                        </small>
                      </TableCell>
                      <TableCell style={{ textAlign: 'center', width: '10%' }}>
                        {customerMembership}
                      </TableCell>
                      <TableCell style={{ textAlign: 'center', width: '10%' }}>
                        {dob && moment(dob, 'x').format('M/D/YYYY')}
                      </TableCell>
                      <TableCell style={{ textAlign: 'center', width: '10%' }}>
                        {dob && moment().diff(moment(dob, 'x'), 'years')}
                      </TableCell>
                      <TableCell style={{ textAlign: 'center', width: '20%' }}>
                        {player.customer.ID !== this.props.team.captain_id && (
                          <Tooltip title="Make captain">
                            <IconButton
                              onClick={() =>
                                this.props.updateCaptain(player.customer.ID)
                              }
                              className={classes.root}
                            >
                              <StarBorderIcon fontSize="small" />
                            </IconButton>
                          </Tooltip>
                        )}
                        <Link
                          href={`/customer/${player.customer.ID}`}
                          target="_blank"
                        >
                          <IconButton className={classes.root}>
                            <ProfileIcon color="primary" fontSize="small" />
                          </IconButton>
                        </Link>
                        <IconButton
                          className={classes.root}
                          onClick={() => this.props.deletePlayer(player.ID)}
                        >
                          <DeleteIcon
                            color="secondary"
                            fontSize="small"
                            className="del-btn"
                          />
                        </IconButton>
                        <IconButton
                          className={classes.root}
                          onClick={() => this.props.changeTeam(player)}
                        >
                          <EjectIcon color="primary" fontSize="small" />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  )
                })}
              </TableBody>
            </Table>
          </div>
        </Paper>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => ({
  team: state.teams.team
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  gotoPage: id => dispatch(push('/customer/' + id)),
  updateCaptain: id => dispatch(updateCaptain(id)),
  deletePlayer: id =>
    dispatch(openDeleteModal('player', () => removePlayer(id))),
  changeTeam: player => {
    dispatch(actions.updateChangeTeamInfo({ key: 'player', value: player }))
    dispatch(actions.toggleChangeTeamModal({ open: true }))
  },
  getSuspendedPlayers: () => dispatch(getSuspendedPlayers()),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(Roster))
