import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  contracts: [],
  contract: null,
  search: {
    facility_ids: [],
    product_type_id: null,
    query: '',
    last_event_date: null,
    hide_paid: false,
  },
  creation_modal_open: false,
  new_contract: {
    facility_id: null,
    name: '',
    comment: '',
    product_type_id: null,
    fee_id: null,
    fee: 100,
    percentage: 50.0,
    customer: null,
    events: [],
    ApplyProcessingFee: true,
  },
  tab: 'events',
  new_event_modal_open: false,
  new_event_modal_edit: false,
  products: [],
  invoice: null,
  new_invoice_item: {
    product_id: null,
    price: 0,
    quantity: 1,
  },
  search_query: '',
  fees: [],
  fee_dialog_open: false,
  alert_message_open: false,
  alert_message: null,
  ptype_update_modal_open: false,
  events_edit_modal_open: false,
}

const contractSlice = createSlice({
    name: 'contract',
    initialState,
    reducers: {
      getContracts: (state, { payload }) => {
        state.contracts = payload.contracts
      },
      getContract: (state, { payload }) => {
        state.contract = {
          ...payload.contract,
          events: payload.contract.events.sort(
            (a, b) => a.start_date - b.start_date
          ),
        }
        state.new_event_modal_open = false
        state.new_invoice_item.quantity = payload.contract.events.length
      },
      updateSearch: (state, { payload }) => {
        state.search = {
          ...state.search,
          [payload.key]: payload.value,
        }
      },
      toggleCreationModal: (state, { payload }) => {
        state.creation_modal_open = payload.open
        state.new_contract.facility_id = payload.facility_id
      },
      changeTab: (state, { payload }) => {
        state.tab = payload.tab
      },
      changeContractDetails: (state, { payload }) => {
        state.new_contract = {
          ...state.new_contract,
          [payload.field]: payload.value,
        }
      },
      toggleEventCreationModal: (state, { payload }) => {
        state.new_event_modal_edit = false
        state.new_event_modal_open = payload.open
      },
      toggleEventEditModal: (state, { payload }) => {
        state.new_event_modal_edit = true
        state.new_event_modal_open = payload.open
      },
      getProducts: (state, { payload }) => {
        state.products = payload.products.map(p => {
          let prices = []
          if (p.deposit_price !== 0) {
            prices.push({
              name: 'Deposit',
              value: p.deposit_price,
            })
          }
          if (p.early_bird_price !== 0) {
            prices.push({
              name: 'Early Bird',
              value: p.early_bird_price,
            })
          }
          if (p.individual_price !== 0) {
            prices.push({
              name: 'Individual',
              value: p.individual_price,
            })
          }
          if (p.full_price !== 0) {
            prices.push({
              name: 'Full',
              value: p.full_price,
            })
          }
          return { ...p, prices }
        })
      },
      getInvoice: (state, { payload }) => {
        if (!payload.invoice) {
          state.invoice = null
          return
        }
        state.invoice = {
          ...payload.invoice,
          payments: payload.invoice.payments,
        }
      },
      changeInvoiceItem: (state, { payload }) => {
        state.new_invoice_item = {
          ...state.new_invoice_item,
          [payload.field]: payload.value,
        }
      },
      editSearchQuery: (state, { payload }) => {
        state.search_query = payload.search_query
      },
      getFees: (state, { payload }) => {
        state.fees = payload.fees
      },
      toggleFeeModal: (state, { payload }) => {
        state.fee_dialog_open = payload.open
      },
      setContractDoc: (state, { payload }) => {
        state.contract = {
          ...state.contract,
          doc: payload.doc,
        }
      },
      sendContractDoc: (state, { payload }) => {
        state.contract = {
          ...state.contract,
          sent: true,
        }
      },
      voidContractDoc: (state, { payload }) => {
        state.contract = {
          ...state.contract,
          doc: '',
          sent: false,
          signed: false,
        }
      },
      openAlert: (state, { payload }) => {
        state.alert_message_open = true
        state.alert_message = payload.message
      },
      closeAlert: (state, { payload }) => {
        state.alert_message_open = false
        state.alert_message = null
      },
      deleteContract: (state, { payload }) => {
        state.contracts = state.contracts.filter(
          contract => contract.ID !== payload.id
        )
      },
      togglePtypeModal: (state, { payload }) => {
        state.ptype_update_modal_open = payload.open
      },
      updatePtype: (state, { payload }) => {
        state.contract = {
          ...state.contract,
          product_type: payload.new_p_type,
        }
      },
      deleteEvent: (state, { payload }) => {
        state.contract = {
          ...state.contract,
          events: state.contract.events.filter(e => e.ID !== payload.id),
        }
      },
      updateEvent: (state, { payload }) => {
        state.contract = {
          ...state.contract,
          events: state.contract.events.map(e => {
            if (e.ID === payload.id) {
              return payload.details
            }
            return e
          }),
        }
      },
      toggleEditEventsModal: (state, { payload }) => {
        state.events_edit_modal_open = payload.open
      },
    },
  }),
  actions = contractSlice.actions

export { contractSlice, actions }
