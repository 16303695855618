import axios from 'axios'
import api from 'api'
import moment from 'moment'
import { v4 as uuidv4 } from 'uuid'
import { ERP_BACKEND_URL } from 'consts'
import { unixToDateTime, momentDateTimeToUnix } from 'helper'
import { changeLoading, dispatchError } from 'common/actions'
import { actions } from 'common/redux'
import { getInvoiceByContractID, getInvoiceByEventID } from 'invoice/actions'

import { calendarSlice } from './redux'

const getFields = facility => (dispatch, getState) => {
  dispatch(changeLoading(true))
  let facility_id = facility
  if (!facility_id) {
    facility_id = getState().user.facility_id
  }
  return axios
    .get(ERP_BACKEND_URL + `/facility/fields?facility_id=${facility_id}`)
    .then(resp => {
      dispatch(calendarSlice.actions.getFields({ fields: resp.data }))
      dispatch(changeLoading(false))
    })
    .catch(e => dispatch(dispatchError(e)))
}

const getMatchEventDetails = event => {
  if (!event.match_id) {
    return Promise.resolve(event)
  }
  return axios
    .get(ERP_BACKEND_URL + `/match/` + event.match_id)
    .then(resp => ({ ...event, match: resp.data }))
}

const getEvents = (moment_start, moment_end) => (dispatch, getState) => {
  dispatch(
    calendarSlice.actions.setCurrentDate({
      moment_start: momentDateTimeToUnix(moment_start),
    })
  )
  const start = momentDateTimeToUnix(moment_start),
    day = moment_start.format('MM/DD/YYYY'),
    dayOfWeek = moment_start.day(),
    is_weekend = dayOfWeek === 0 || dayOfWeek === 6
  let end = null
  if (moment_end) {
    end = momentDateTimeToUnix(moment_end)
  } else {
    end = momentDateTimeToUnix(moment_start.add(1, 'd'))
  }
  dispatch(changeLoading(true))
  const facility_id = getState().user.facility_id
  return axios
    .get(
      ERP_BACKEND_URL +
        `/event?facility_id=${facility_id}&start=${start}&end=${end}`
    )
    .then(resp => Promise.all(resp.data.map(getMatchEventDetails)))
    .then(events => {
      dispatch(
        calendarSlice.actions.getEvents({
          events,
          day,
          is_weekend,
        })
      )
      dispatch(changeLoading(false))
    })
    .catch(e => dispatch(dispatchError(e)))
}
const getConflicts = (moment_start, moment_end) => (dispatch, getState) => {
  const start = momentDateTimeToUnix(moment_start)
  let end = null
  if (moment_end) {
    end = momentDateTimeToUnix(moment_end)
  } else {
    end = momentDateTimeToUnix(moment_start.add(1, 'd'))
  }
  dispatch(changeLoading(true))
  const facility_id = getState().user.facility_id
  return axios
    .get(
      ERP_BACKEND_URL +
        `/event/conflicts?facility_id=${facility_id}&start=${start}&end=${end}`
    )
    .then(conflicts => {
      dispatch(
        calendarSlice.actions.getConflicts({
          conflicts: conflicts.data,
        })
      )
      dispatch(changeLoading(false))
    })
    .catch(e => dispatch(dispatchError(e)))
}

const getEvent = event_id => (dispatch, getState) => {
  dispatch(changeLoading(true))
  return api.event
    .get(event_id)
    .then(getMatchEventDetails)
    .then(event => {
      dispatch(calendarSlice.actions.getEvent({ event }))
      dispatch(changeLoading(false))
      if (event.match?.ID) {
        return dispatch(calendarSlice.actions.changeTabMenu({ tab: 'league' }))
      }
      if (event.contract_id && event.product_type_id !== 5) {
        // pickup is specific
        dispatch(getInvoiceByContractID(event.contract_id))
      } else {
        dispatch(getInvoiceByEventID(event.ID))
      }
    })
    .catch(e => dispatch(dispatchError(e)))
}

const searchCustomers = query => (dispatch, getState) => {
  if (query.trim() === '') return
  dispatch(changeLoading(true))
  const query_with_facility = `${query}?facility=${getState().user.facility_id}`
  return axios
    .get(ERP_BACKEND_URL + `/customer/search/` + query_with_facility)
    .then(resp => {
      dispatch(
        calendarSlice.actions.getCustomers({
          customers: resp.data,
        })
      )
      dispatch(changeLoading(false))
    })
    .catch(e => dispatch(dispatchError(e)))
}

const getCoaches = facility_id => (dispatch, getState) => {
  dispatch(changeLoading(true))
  return axios
    .get(ERP_BACKEND_URL + `/coaches?facility_id=${facility_id}`)
    .then(resp => {
      dispatch(
        calendarSlice.actions.getCustomers({
          customers: resp.data,
        })
      )
      dispatch(changeLoading(false))
    })
    .catch(e => dispatch(dispatchError(e)))
}

const createOrUpdateCustomer = body => {
  return axios
    .post(ERP_BACKEND_URL + `/customer`, body)
    .then(resp => resp.data.ID)
}

const getCreateOrSkipCustomer = (event_details, facility_id) => {
  if (!event_details.customer) {
    return Promise.reject(`Customer can't be empty`)
  }
  let customer_body = {
    first_name: event_details.customer.first_name,
    last_name: event_details.customer.last_name,
    email: event_details.customer.email,
    facility_id,
    customer_type_id: 1,
  }
  if (event_details.customer.hubspot && event_details.customer.hubspot.phone) {
    customer_body.hubspot = {
      phone: event_details.customer.hubspot.phone,
    }
  }
  return createOrUpdateCustomer(customer_body)
}

const saveEvent = () => (dispatch, getState) => {
  dispatch(changeLoading(true))
  const event_details = getState().calendar.event_details,
    facility_id = getState().user.facility_id,
    block_token = uuidv4()
  let events = []
  if (event_details.end_time <= event_details.start_time) {
    return dispatch(dispatchError('End time cannot be before start time'))
  }
  let p = Promise.resolve(0) // allow 0 customer for blocker
  if (![13, 19].includes(event_details.product_type_id)) {
    //Blocker or parti blocker
    p = getCreateOrSkipCustomer(event_details, facility_id)
  }
  return p
    .then(customer_id => {
      if (!event_details.repeat_events?.length) {
        let body = {
          field_id: event_details.field_id,
          product_type_id: event_details.product_type_id,
          start_date: event_details.start_time,
          end_date: event_details.end_time,
          comment: event_details.comment,
          block_token,
        }
        if (customer_id) {
          body.customer_id = customer_id
        }
        if ([3, 8].indexOf(event_details.product_type_id) > -1) {
          body.team_a = event_details.team_a
          body.team_b = event_details.team_b
        }
        events.push(body)
      } else {
        if (event_details.repeat_events.filter(e => !e.is_ok).length > 0) {
          return Promise.reject('Please check that all events have free spots')
        }
        event_details.repeat_events.forEach(event => {
          let repeatObject = {
            field_id: event.field_id,
            product_type_id: event.product_type_id,
            start_date: event.start_date,
            end_date: event.end_date,
            comment: event_details.comment,
            block_token,
          }
          if (customer_id) {
            repeatObject.customer_id = customer_id
          }
          events.push(repeatObject)
        })
      }
      const request = events.map(event =>
        axios.post(ERP_BACKEND_URL + '/event', event)
      )
      return Promise.all(request)
    })
    .then(resp => {
      if (event_details.product_type_id !== 13)
        window.location = `/calendar/event/${resp[0].data.ID}`
      else dispatch(getEvents(moment().startOf('day')))
    })
    .catch(e => dispatch(dispatchError(e)))
}

const updateEvent = () => (dispatch, getState) => {
  dispatch(changeLoading(true))
  const event_details = getState().calendar.event_details,
    facility_id = getState().user.facility_id
  if (event_details.end_time <= event_details.start_time) {
    return dispatch(dispatchError('End time cannot be before start time'))
  }
  let p = Promise.resolve(0) // allow 0 customer for blocker
  if (![13, 19].includes(event_details.product_type_id)) {
    //Blocker or parti blocker
    p = getCreateOrSkipCustomer(event_details, facility_id)
  }
  return p
    .then(customer_id => {
      const body = {
        product_type_id: event_details.product_type_id,
        customer_id: event_details.customer.ID,
        field_id: event_details.field_id,
        start_date: event_details.start_time,
        end_date: event_details.end_time,
        comment: event_details.comment,
      }
      return axios
        .put(ERP_BACKEND_URL + '/event/' + event_details.ID, body)
        .then(resp => {
          dispatch(
            calendarSlice.actions.updateEvent({
              event: body,
              id: event_details.ID,
            })
          )
          dispatch(changeLoading(false))
        })
    })
    .catch(e => dispatch(dispatchError(e)))
}

const updateEventTimes =
  (event_id, field_id, start, duration) => (dispatch, getState) => {
    dispatch(changeLoading(true))
    const unixStart = momentDateTimeToUnix(moment(start))
    const body = {
      field_id,
      start_date: momentDateTimeToUnix(moment(start)),
      end_date: unixStart + duration,
    }
    return axios
      .put(ERP_BACKEND_URL + '/event/' + event_id, body)
      .then(resp => {
        dispatch(
          getEvents(moment(getState().calendar.current_date, 'M/D/YYYY'))
        )
      })
      .catch(e => dispatch(dispatchError(e)))
  }

const deleteEvent = event_id => {
  return axios.delete(ERP_BACKEND_URL + `/event/` + event_id.toString())
}

const deleteBlockEvent = id =>
  axios.delete(ERP_BACKEND_URL + `/event/block/` + id)

const deleteEventAndInvoice = () => (dispatch, getState) => {
  const { ID } = getState().calendar.event_details,
    { invoice } = getState().invoice
  if (!invoice && !invoice.ID) {
    return deleteEvent(ID)
      .then(() => {
        window.location = `/`
      })
      .catch(e => dispatch(dispatchError(e)))
  }
  return axios
    .delete(ERP_BACKEND_URL + `/invoice/` + invoice.ID.toString())
    .then(() => {
      return deleteEvent(ID)
    })
    .then(() => {
      window.location = `/`
    })
    .catch(e => dispatch(dispatchError(e)))
}

const calculateEventRepeat = () => (dispatch, getState) => {
  const event_details = getState().calendar.event_details
  let moment_start = unixToDateTime(event_details.start_time),
    moment_end = unixToDateTime(event_details.end_time)
  let repeat_events = [
    {
      field_id: event_details.field_id,
      product_type_id: event_details.product_type_id,
      start_date: momentDateTimeToUnix(moment_start),
      end_date: momentDateTimeToUnix(moment_end),
      comment: event_details.comment,
    },
  ]
  if (event_details.repeat_method === 'daily') {
    if (event_details.end_method === 'count') {
      for (let i = 0; i < parseInt(event_details.end_count) - 1; i++) {
        moment_start.add(event_details.repeat_frequency, 'd')
        moment_end.add(event_details.repeat_frequency, 'd')
        repeat_events.push({
          field_id: event_details.field_id,
          product_type_id: event_details.product_type_id,
          start_date: momentDateTimeToUnix(moment_start),
          end_date: momentDateTimeToUnix(moment_end),
          comment: event_details.comment,
        })
      }
    }
    if (event_details.end_method === 'until') {
      const moment_final_end = moment(event_details.end_date).format(
        'YYYY-MM-DD'
      )
      while (moment_start.format('YYYY-MM-DD') !== moment_final_end) {
        moment_start.add(event_details.repeat_frequency, 'd')
        moment_end.add(event_details.repeat_frequency, 'd')
        repeat_events.push({
          field_id: event_details.field_id,
          product_type_id: event_details.product_type_id,
          start_date: momentDateTimeToUnix(moment_start),
          end_date: momentDateTimeToUnix(moment_end),
          comment: event_details.comment,
        })
      }
    }
  }
  if (event_details.repeat_method === 'weekly') {
    if (event_details.end_method === 'count') {
      for (let i = 0; i < parseInt(event_details.end_count) - 1; i++) {
        moment_start.add(event_details.repeat_frequency, 'w')
        moment_end.add(event_details.repeat_frequency, 'w')
        for (let j = 0; j < 7; j++) {
          const sub_moment_start = moment_start.clone().add(j, 'd')
          const sub_moment_end = moment_end.clone().add(j, 'd')
          if (
            event_details.repeat_days.indexOf(
              sub_moment_start.weekday().toString()
            ) > -1
          ) {
            repeat_events.push({
              field_id: event_details.field_id,
              product_type_id: event_details.product_type_id,
              start_date: momentDateTimeToUnix(sub_moment_start),
              end_date: momentDateTimeToUnix(sub_moment_end),
              comment: event_details.comment,
            })
          }
        }
      }
    }
    if (event_details.end_method === 'until') {
      const moment_final_end = moment(event_details.end_date).format(
        'YYYY-MM-DD'
      )
      while (moment_start.format('YYYY-MM-DD') !== moment_final_end) {
        moment_start.add(event_details.repeat_frequency, 'w')
        moment_end.add(event_details.repeat_frequency, 'w')
        for (let j = 0; j < 7; j++) {
          const sub_moment_start = moment_start.clone().add(j, 'd')
          const sub_moment_end = moment_end.clone().add(j, 'd')
          if (
            event_details.repeat_days.indexOf(
              sub_moment_start.weekday().toString()
            ) > -1
          ) {
            repeat_events.push({
              field_id: event_details.field_id,
              product_type_id: event_details.product_type_id,
              start_date: momentDateTimeToUnix(sub_moment_start),
              end_date: momentDateTimeToUnix(sub_moment_end),
              comment: event_details.comment,
            })
          }
          if (sub_moment_start.format('YYYY-MM-DD') === moment_final_end) {
            moment_start = sub_moment_start
            break
          }
        }
      }
    }
  }
  return axios
    .post(ERP_BACKEND_URL + `/event/recurrent`, {
      events: repeat_events,
    })
    .then(resp => {
      dispatch(
        calendarSlice.actions.changeEventDetails({
          field: 'repeat_events',
          value: resp.data,
        })
      )
      dispatch(calendarSlice.actions.notifyRepeatChange(false))
    })
    .catch(e => dispatch(dispatchError(e)))
}

const runNewCheck = setEdit => (dispatch, getState) => {
  dispatch(changeLoading(true))
  return axios
    .post(ERP_BACKEND_URL + `/event/recurrent`, {
      events: getState().calendar.event_details.repeat_events,
    })
    .then(resp => {
      dispatch(
        calendarSlice.actions.changeEventDetails({
          field: 'repeat_events',
          value: resp.data,
        })
      )
      if (setEdit) setEdit()
      dispatch(changeLoading(false))
    })
    .catch(e => dispatch(dispatchError(e)))
}

const runSingleCheckAndSave = id => (dispatch, getState) => {
  dispatch(changeLoading(true))
  const events = getState().calendar.event_details.repeat_events.filter(
    e => e.ID === id
  )
  return axios
    .post(ERP_BACKEND_URL + `/event/recurrent`, {
      events,
    })
    .then(resp => {
      const ev = resp.data[0]
      return {
        field_id: ev.field_id,
        start_date: ev.start_date,
        end_date: ev.end_date,
        to_check: false,
        is_ok: ev.is_ok,
      }
    })
    .then(new_event => {
      if (new_event.is_ok) {
        return axios
          .put(ERP_BACKEND_URL + '/event/' + id, new_event)
          .then(() => new_event)
      }
      return new_event
    })
    .then(new_event => {
      dispatch(
        calendarSlice.actions.updateSingleRecurring({
          id,
          event: { ...new_event },
        })
      )
      dispatch(changeLoading(false))
    })
    .catch(e => dispatch(dispatchError(e)))
}

const updateNoShow = no_show => (dispatch, getState) => {
  dispatch(changeLoading(true))
  const event_details = getState().calendar.event_details
  return axios
    .put(
      ERP_BACKEND_URL +
        `/event/no_show/${event_details.ID}?no_show=${no_show ? 1 : 0}`
    )
    .then(resp => {
      dispatch(calendarSlice.actions.updateNoShow({ no_show }))
      dispatch(changeLoading(false))
    })
    .catch(e => dispatch(dispatchError(e)))
}

const updateEventVideo = () => (dispatch, getState) => {
  dispatch(changeLoading(true))
  const event_details = getState().calendar.event_details
  return axios
    .put(ERP_BACKEND_URL + `/event/video/${event_details.ID}`)
    .then(resp => {
      dispatch(
        calendarSlice.actions.updateVideo({
          video_visible: !event_details.video_visible,
        })
      )
      dispatch(changeLoading(false))
    })
    .catch(e => dispatch(dispatchError(e)))
}

const getSoccernautParticipants = () => (dispatch, getState) => {
  dispatch(changeLoading(true))
  const { ID } = getState().calendar.event_details
  return axios
    .get(ERP_BACKEND_URL + `/event/participants/${ID}`)
    .then(resp => {
      dispatch(
        calendarSlice.actions.setSoccernautParticipants({
          participants: resp.data.map(ev => {
            const { customer } = ev
            return {
              ID: customer.ID,
              first_name: customer.first_name,
              last_name: customer.last_name,
              email: customer.email.split('##')[0],
              is_kid: customer.is_kid,
              waiver_signed: customer.waiver_signed,
            }
          }),
        })
      )
    })
    .catch(e => dispatch(dispatchError(e)))
}

const updateSoccernautCoach = user_id => (dispatch, getState) => {
  dispatch(changeLoading(true))
  const { ID } = getState().calendar.event_details
  return axios
    .put(ERP_BACKEND_URL + `/event/coach/${ID}?coach_id=${user_id}`)
    .then(resp => {
      dispatch(
        calendarSlice.actions.changeEventDetails({
          field: 'coach_id',
          value: user_id,
        })
      )
      dispatch(changeLoading(false))
    })
    .catch(e => dispatch(dispatchError(e)))
}

const getEventVideo = id => (dispatch, getState) => {
  dispatch(changeLoading(true))
  return api.event
    .video(id)
    .then(video => {
      dispatch(
        calendarSlice.actions.changeEventDetails({
          field: 'video',
          value: `https://webapp.sofive.com/svideo.html?video_id=${video.ID}`,
        })
      )
      dispatch(changeLoading(false))
    })
    .catch(console.log)
}

const sendEmailConfirmation = id => (dispatch, getState) => {
  dispatch(changeLoading(true))
  return api.event
    .emailConfirm(id)
    .then(() => {
      dispatch(changeLoading(false))
      dispatch(actions.openInfoModal({ message: 'Email confirmation sent' }))
    })
    .catch(e => dispatch(dispatchError(e)))
}

const getPickupParticipants = () => (dispatch, getState) => {
  dispatch(changeLoading(true))
  const { ID } = getState().calendar.event_details
  return api.event
    .getPickupPlayers(ID)
    .then(pickup_players => {
      dispatch(changeLoading(false))
      dispatch(calendarSlice.actions.setPickupPlayers({ pickup_players }))
    })
    .catch(e => dispatch(dispatchError(e)))
}

const checkIn = (id, checked_in) => (dispatch, getState) => {
  dispatch(changeLoading(true))
  return api.event
    .checkInPickupPlayers(id, checked_in)
    .then(pickup_players => {
      dispatch(changeLoading(false))
      dispatch(calendarSlice.actions.checkIn({ id, checked_in }))
    })
    .catch(e => dispatch(dispatchError(e)))
}

const getHealthChecks = () => (dispatch, getState) => {
  dispatch(changeLoading(true))
  const { ID } = getState().calendar.event_details
  return api.event
    .getHealthChecks(ID)
    .then(health_checks => {
      dispatch(changeLoading(false))
      dispatch(calendarSlice.actions.setHealthChecks({ health_checks }))
    })
    .catch(e => dispatch(dispatchError(e)))
}

const setContractEventRepeat = () => (dispatch, getState) => {
  const repeat_events = getState().contract.contract.events.map(e => ({
    ...e,
    is_ok: true,
    to_check: false,
  }))
  dispatch(
    calendarSlice.actions.changeEventDetails({
      field: 'repeat_events',
      value: repeat_events,
    })
  )
}

const checkAttendance = attendance => (dispatch, getState) => {
  dispatch(changeLoading(true))
  dispatch(calendarSlice.actions.changeCustomerAttendance({ attendance }))
  return axios.post(ERP_BACKEND_URL + `/attendance`, attendance).then(() => {
    dispatch(changeLoading(false))
  })
}
const loadHomeTeam = (start_date) => (dispatch, getState) => {
  dispatch(changeLoading(true))
  _getTeam(getState().calendar.event_details.match?.home_team?.ID).then(
    team => {
      const suspended_players = getSuspendedPlayers(team, start_date)
      team = {...team, suspended_players: suspended_players}
      dispatch(calendarSlice.actions.getTeamHome({ team }))
    }
  )
  dispatch(changeLoading(false))
}

const loadAwayTeam = (start_date) => (dispatch, getState) => {
  dispatch(changeLoading(true))
  _getTeam(getState().calendar.event_details.match?.away_team?.ID).then(
    team => {
      const suspended_players = getSuspendedPlayers(team, start_date)
      team = {...team, suspended_players: suspended_players}
      dispatch(calendarSlice.actions.getTeamAway({ team }))
    }
  )
  dispatch(changeLoading(false))
}

const _getTeam = id =>
  axios.get(ERP_BACKEND_URL + `/team/` + id).then(resp => resp.data)

const getSuspendedPlayers = (team, start_date)  => {
  const players = team.roster || []
  let suspended_players = []
  for (let player of players) {
    if (player.customer.suspensions && player.customer.suspensions.length > 0) {
      for (let suspension of player.customer.suspensions) {
        if (suspension.until > start_date) {
          suspended_players.push(player.customer.first_name + ' ' + player.customer.last_name)
        }
      }
    }
  }
  return suspended_players
}

export {
  getFields,
  getEvents,
  searchCustomers,
  saveEvent,
  deleteEvent,
  updateEvent,
  getEvent,
  calculateEventRepeat,
  runNewCheck,
  updateEventTimes,
  updateNoShow,
  deleteEventAndInvoice,
  getSoccernautParticipants,
  getCoaches,
  updateSoccernautCoach,
  getEventVideo,
  sendEmailConfirmation,
  getPickupParticipants,
  checkIn,
  getHealthChecks,
  setContractEventRepeat,
  runSingleCheckAndSave,
  deleteBlockEvent,
  updateEventVideo,
  checkAttendance,
  loadHomeTeam,
  loadAwayTeam,
  getConflicts,
}
