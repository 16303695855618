import React from 'react'
import { connect } from 'react-redux'

import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Link from '@material-ui/core/Link'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import Breadcrumbs from '@material-ui/core/Breadcrumbs'
import MuiAlert from '@material-ui/lab/Alert'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Switch from '@material-ui/core/Switch'
import DeleteIcon from '@material-ui/icons/Delete'

import Menu from './../components/Menu'
import ProductFieldTimeForm from './../components/forms/ProductFieldTimeSection'
import CustomerSection from './../components/forms/CustomerSection'
import SoccernautSection from './../components/forms/SoccernautSection'
import PickupSection from './../components/forms/PickupSection'
import HealthCheckSection from './../components/forms/HealthCheckSection'
import LeagueSection from './../components/forms/LeagueSection'
import LogList from './../../common/components/LogTable'
import Invoice from 'invoice/components/Invoice'
import PresenceTable from '../components/PresenceTable'

import { colors } from './../../consts'
import {
  getFields,
  deleteEvent,
  getEvent,
  updateEvent,
  updateNoShow,
  updateEventVideo,
  deleteEventAndInvoice,
  getEventVideo,
  sendEmailConfirmation,
  deleteBlockEvent,
} from './../actions'
import {
  openDeleteModal,
  openConfirmModal,
  getEventLogs,
} from './../../common/actions'

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />
}

const RedSwitch = withStyles({
  switchBase: {
    color: colors.red_mild,
    '&$checked': {
      color: colors.red_strong,
    },
    '&$checked + $track': {
      backgroundColor: colors.red_strong,
    },
  },
  checked: {},
  track: {},
})(Switch)

class EventDialog extends React.Component {
  componentDidMount() {
    this.props.getFields()
    const { id } = this.props.match.params
    this.props.getEvent(id)
    this.props.getEventVideo(id)
    this.props.getEventLogs(id)
  }

  render() {
    return (
      <div>
        {this.props.event.ID && (
          <Grid
            container
            direction="row"
            justify="flex-start"
            alignItems="flex-start"
            style={{ textAlign: 'center' }}
            spacing={4}
          >
            {this.props.event?.date_parsed && (
              <Grid item xs={12} style={{ paddingTop: 0, paddingBottom: 0 }}>
                <Breadcrumbs separator="›" aria-label="breadcrumb">
                  <Link
                    color="inherit"
                    href={`/calendar?date=${this.props.event.date_parsed}`}
                  >
                    Return to calendar
                  </Link>
                  <Typography color="textPrimary">Event</Typography>
                </Breadcrumbs>
              </Grid>
            )}
            {[10, 17].includes(this.props.event.product_type_id) && (
              <Grid item xs={12}>
                <Alert severity="info">
                  This is a class, to edit it please head over to the class page{' '}
                  <Link
                    href={`/classes/${this.props.event.class_id}`}
                    target="_blank"
                    style={{ color: 'white', marginLeft: 5 }}
                  >
                    <u>here</u>
                  </Link>
                </Alert>
              </Grid>
            )}
            {!this.props.can_edit && (
              <Grid item xs={12}>
                <Alert severity="info">
                  This is a contract event, to edit it please head over to the
                  contract page{' '}
                  <Link
                    href={`/contract/${this.props.event.contract_id}`}
                    target="_blank"
                    style={{ color: 'white', marginLeft: 5 }}
                  >
                    <u>here</u>
                  </Link>
                </Alert>
              </Grid>
            )}
            <Grid item xs={6}>
              <Typography variant="h5">
                Event details
                <br />
                <small style={{ fontSize: '0.6em', margin: 0, padding: 0 }}>
                  {this.props.facility}
                </small>
                <div style={{ marginBottom: '5px' }}>
                  <Link
                    href={`https://webapp.sofive.com/event.html?id=${this.props.event.ID}`}
                    target="_blank"
                    style={{ padding: 5 }}
                  >
                    <Button variant="outlined" size="small" color="primary">
                      Event page
                    </Button>
                  </Link>
                  <Link
                    onClick={() =>
                      this.props.sendEmailConfirmation(this.props.event.ID)
                    }
                    style={{ padding: 5 }}
                  >
                    <Button variant="outlined" size="small" color="primary">
                      Re-send confirmation email
                    </Button>
                  </Link>
                  <Link
                    style={{ padding: 5 }}
                    href={`/customer/${this.props.customer_id}`}
                    target="_blank"
                  >
                    <Button variant="outlined" size="small" color="primary">
                      Customer
                    </Button>
                  </Link>
                </div>
              </Typography>
              {[1, 2, 11].indexOf(this.props.event.product_type_id) !== -1 && (
                <FormControlLabel
                  control={
                    <RedSwitch
                      checked={this.props.event.no_show}
                      color="secondary"
                      onChange={e => this.props.updateNoShow(e.target.checked)}
                      name="no_show"
                    />
                  }
                  label="No show"
                />
              )}
              <br />
                <FormControlLabel
                  control={
                    <RedSwitch
                      checked={this.props.event.video_visible}
                      color="secondary"
                      onChange={e => this.props.updateEventVideo()}
                      name="video_visible"
                    />
                  }
                  label="Video visible"
                />
              {/* )} */}
              <ProductFieldTimeForm />
              {[1, 2, 6, 7, 11, 12, 14].indexOf(
                this.props.event.product_type_id
              ) !== -1 && <CustomerSection no_create hide_header />}
              {(this.props.can_edit || this.props.is_blocker) && (
                <Grid
                  container
                  direction="row"
                  justify="center"
                  alignItems="center"
                  style={{ textAlign: 'center', marginTop: 20 }}
                  spacing={4}
                >
                  {(!this.props.invoice ||
                    !this.props.invoice.invoice_items.length) &&
                    !this.props.is_mod && (
                      <Grid item xs={4}>
                        <Button
                          onClick={() =>
                            this.props.deleteEvent(this.props.event.ID)
                          }
                          style={{ color: colors.red_strong, width: '100%' }}
                        >
                          Delete
                        </Button>
                      </Grid>
                    )}
                  {(!this.props.invoice ||
                    !this.props.invoice.invoice_items.length) &&
                    !this.props.is_mod &&
                    this.props.event?.block_token &&
                    this.props.event?.product_type_id === 13 && (
                      <Grid item xs={4}>
                        <Button
                          onClick={() => {
                            this.props.deleteBlockEvent(
                              this.props.event?.block_token
                            )
                          }}
                          style={{ color: colors.red_strong, width: '100%' }}
                        >
                          Delete Block series
                        </Button>
                      </Grid>
                    )}
                  {(this.props.is_admin || this.props.is_manager) &&
                    !this.props.has_payments &&
                    this.props.invoice &&
                    this.props.invoice.ID &&
                    !this.props.is_blocker && (
                      <Grid item xs={4}>
                        <Link>
                          <Button
                            onClick={() => {
                              this.props.deleteEventAndInvoice()
                            }}
                            startIcon={<DeleteIcon />}
                            style={{
                              color: colors.red_strong,
                              backgroundColor: colors.red_mild,
                            }}
                          >
                            <b>Invoice & event</b>
                          </Button>
                        </Link>
                      </Grid>
                    )}
                  <Grid item xs={4}>
                    <Link>
                      <Button
                        onClick={() => this.props.updateEvent()}
                        style={{
                          backgroundColor: colors.blue_strong,
                          color: 'white',
                          width: '100%',
                        }}
                      >
                        <b>Save</b>
                      </Button>
                    </Link>
                  </Grid>
                </Grid>
              )}
            </Grid>
            <Grid item xs={6}>
              <Menu />
              {this.props.tab === 'invoice' && (
                <div style={{ marginTop: 20 }}>
                  {this.props.event.contract_id &&
                  this.props.event.product_type_id !== 5 ? (
                    <Typography
                      variant="h5"
                      style={{ marginBottom: 20, marginTop: 0 }}
                    >
                      <Link
                        href={`/contract/${this.props.event.contract_id}`}
                        target="_blank"
                      >
                        {' '}
                        Contract
                      </Link>
                    </Typography>
                  ) : null}
                  <Invoice
                    is_contract={false}
                    product_type_id={this.props.event.product_type_id}
                    facility_id={this.props.event.field.facility_id}
                    field={this.props.fields.find(f => {
                      return f.ID === this.props.event.field_id
                    })}
                    read_only={this.props.is_contract}
                    quantity={1}
                    event={this.props.event}
                    include_manual={
                      [3, 8, 11, 12].indexOf(this.props.event.product_type_id) >
                      -1
                    }
                  />
                </div>
              )}
              {this.props.tab === 'league' && <LeagueSection />}
              {this.props.tab === 'health_check' && <HealthCheckSection />}
              {this.props.tab === 'soccernaut' && <SoccernautSection />}
              {this.props.tab === 'pickup' && <PickupSection />}
              {this.props.tab === 'changes' && <LogList log_type="event" />}
              {this.props.tab === 'attendance' && <PresenceTable />}
            </Grid>
          </Grid>
        )}
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => ({
  open: state.calendar.full_screen_event_modal_open,
  event: state.calendar.event_details,
  can_edit: state.calendar.event_details.contract_id === 0,
  is_blocker: state.calendar.event_details.product_type_id === 13,
  is_admin: state.user.user_type === 'admin',
  is_manager: state.user.user_type === 'manager',
  is_mod: state.user.user_type === 'mod',
  tab: state.calendar.tab_menu,
  customer_id:
    state.calendar.event_details.customer &&
    state.calendar.event_details.customer.ID,
  fields: state.calendar.fields,
  facility:
    state.calendar.event_details.field &&
    state.calendar.event_details.field.facility.name,
  is_contract:
    state.calendar.event_details.contract_id > 0 ||
    state.calendar.event_details.product_type_id == 19,
  invoice: state.invoice.invoice,
  has_payments:
    state.invoice.invoice &&
    state.invoice.invoice.payments.reduce(
      (a, b) => a + b.amount * (b.quantity ? b.quantity : 1),
      0
    ) !== 0,
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  getFields: () => dispatch(getFields()),
  getEvent: id => dispatch(getEvent(id)),
  updateEvent: () => dispatch(updateEvent()),
  updateNoShow: no_show => dispatch(updateNoShow(no_show)),
  updateEventVideo: () => dispatch(updateEventVideo()),
  deleteEvent: id =>
    dispatch(
      openDeleteModal('event', () => {
        deleteEvent(id).then(() => (window.location = '/calendar'))
      })
    ),
  deleteBlockEvent: id =>
    dispatch(
      openDeleteModal('block event', () => {
        deleteBlockEvent(id).then(() => (window.location = '/calendar'))
      })
    ),
  deleteEventAndInvoice: () =>
    dispatch(openDeleteModal('invoice & event', deleteEventAndInvoice)),
  getEventLogs: id => dispatch(getEventLogs('event', id)),
  getEventVideo: id => dispatch(getEventVideo(id)),
  sendEmailConfirmation: id =>
    dispatch(
      openConfirmModal('Email', 'send a confirmation email', () =>
        sendEmailConfirmation(id)
      )
    ),
})

export default connect(mapStateToProps, mapDispatchToProps)(EventDialog)
