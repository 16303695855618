import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { connect } from 'react-redux'

import Paper from '@material-ui/core/Paper'
import memoize from 'memoize-one'
import DataTable from 'react-data-table-component'
import ArrowDownward from '@material-ui/icons/ArrowDownward'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import EditIcon from '@material-ui/icons/Edit'

import NewProductColorDialog from 'resources/components/NewProductColorDialog'
import { Button } from '@material-ui/core'

import { actions } from 'resources/redux'
import NewProductTypeDialog from '../components/NewProductTypeDialog'

const sortIcon = <ArrowDownward />

const columns = (editProductType, setProductColor) =>
  memoize(() => [
    {
      name: 'ID',
      selector: 'id',
      center: true,
      cell: productType => productType.ID,
    },
    {
      name: 'Name',
      selector: 'name',
      center: true,
      cell: productType => productType.name,
    },
    {
      name: 'Category',
      selector: 'category',
      center: true,
      cell: productType => productType.category,
    },
    {
      name: 'Reporting',
      selector: 'reporting',
      center: true,
      cell: productType => productType.reporting,
    },
    {
      name: 'GL code',
      selector: 'productType_code',
      center: true,
      cell: productType => productType.gl,
    },
    {
      name: 'Show In calendar',
      selector: 'showInCalendar',
      center: true,
      cell: productType => `${productType.showInCalendar ? '✅' : '❌'}`,
    },
    {
      name: 'Product color',
      selector: 'product_type',
      center: true,
      cell: productType => {
        if (productType.color != '') {
          return (
            <>
              <div
                onClick={() => setProductColor(productType)}
                style={{
                  backgroundColor: productType.color,
                  cursor: 'pointer',
                  height: '50%',
                  width: '50%',
                  border: 'solid black',
                }}
              />
            </>
          )
        }
        return (
          <>
            <div
              onClick={() => {
                setProductColor(productType)
              }}
              style={{
                backgroundColor: productType.color,
                color: 'red',
                cursor: 'pointer',
              }}
            >
              Please set a color
            </div>
          </>
        )
      },
    },
    {
      name: 'Actions',
      selector: 'actions',
      center: true,
      cell: productType => (
        <>
          <IconButton onClick={() => editProductType(productType)}>
            <EditIcon fontSize="small" />
          </IconButton>
        </>
      ),
    },
  ])

const ProductTypeManager = ({ editProductType, openColor }) => {
  const productTypes = useSelector(state => state.resources.product_types),
    dispatch = useDispatch(),
    openDialog = () => {
      dispatch(actions.resetProductType())
      dispatch(
        actions.updateProductType({ key: 'open', value: true, reset: true })
      )
    }
  return (
    <div>
      <NewProductTypeDialog />
      <NewProductColorDialog />
      <Grid
        container
        direction="row"
        justify="center"
        alignItems="flex-start"
        spacing={4}
      >
        <Grid item xs={12}>
          <Button onClick={openDialog} variant="outlined">
            Add new
          </Button>
          <Paper elevation={0}>
            <DataTable
              columns={columns(editProductType, openColor)()}
              data={productTypes}
              highlightOnHover
              defaultSortField="ID"
              defaultSortAsc={true}
              sortIcon={sortIcon}
              fixedHeader
              customStyles={{
                rows: {
                  style: {
                    textAlign: 'center',
                  },
                },
              }}
            />
          </Paper>
        </Grid>
      </Grid>
    </div>
  )
}

const mapStateToProps = (state, ownProps) => ({
  ...state.resources,
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  editProductType: product_type => {
    dispatch(actions.setProductType(product_type))
    dispatch(actions.updateProductType({ key: 'open', value: true }))
  },
  openColor: product_type => {
    dispatch(actions.setProductType(product_type))
    dispatch(actions.updateProductType({ key: 'openColor', value: true }))
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(ProductTypeManager)
